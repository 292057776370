/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, arrayInsert, arrayRemove, getFormValues } from "redux-form";
import { bindActionCreators } from "redux";

import {
  CNPJ,
  customFormatCnpjCpf,
  extrairDocumentoFornecedor,
} from "../../../../../../utils/helpers/StringHelper";

import If from "../../../../../common/If/If";
import Input from "../../../../../common/input/Input";
import MaskedInput from "../../../../../common/input/MaskedInput";
import MaskedFormatInput from "../../../../../common/input/MaskedFormatInput";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Textarea from "../../../../../common/input/Textarea";

import { changeMoeda, mudaDependenteOnChange, getNaturezaLastro } from "../../../../../../../src/actions/ativo/AtivoAction";
import Button from "../../../../../common/button/Button";
import { getFornecedores } from "../../../../../../actions/prestadora/PrestadoraAction";
import GenericPickerInput from "../../../../../common/input/GenericPickerInput";
import { CSSTransition, TransitionGroup } from "react-transition-group";

let AtivoFormGeral = (props) => {
  const {
    readOnly,
    devedorAtivoList,
    originadorAtivoList,
    contratoAtivoList,
    segmentoAtivoList,
    auditoriaList,
    servicerList,
    custodianteList,
    performanceList,
    tipoDevedorList,
    moedasList,
    changeMoeda,
    mudaDependenteOnChange,
    fornecedorList,
    getFornecedores,
    formValues,

    razaoSocial,
    arrayInsert,
    arrayRemove,

    segmentoAtivo,
    naturezaLastroList,
    getNaturezaLastro,
  } = props;

  const [refreshKey, setRefreshKey] = React.useState(0)

  React.useEffect(() => {
    if (originadorAtivoList.length === 0) {
      addOriginador(originadorAtivoList.length);
    }

    if (devedorAtivoList.length === 0) {
      addDevedorAtivo(devedorAtivoList.length);
    }
  }, []);

  React.useEffect(() => {
    setRefreshKey(refreshKey + 1)
  }, [originadorAtivoList.length, devedorAtivoList.length, razaoSocial])

  // 

  const addOriginador = (index) => {
    if (!readOnly) {
      arrayInsert("ativoForm", "originadorAtivoList", index, {});
    }
  }
  
  const removeOriginador = (index) => {
    if (!readOnly && originadorAtivoList.length > 0) {
      arrayRemove("ativoForm", "originadorAtivoList", index);
    }
  }
  
  const addDevedorAtivo = (index) => {
    if (!readOnly) {
      arrayInsert("ativoForm", "devedorAtivoList", index, {});
    }
  }
  
  const removeDevedorAtivo = (index) => {
    if (!readOnly && devedorAtivoList.length > 0) {
      arrayRemove("ativoForm", "devedorAtivoList", index);
    }
  }
  
  const allowedPercentualCedido = (value) => {
    const { formattedValue, floatValue } = value;
    return formattedValue === "" || floatValue <= 100;
  }

  let ativoHeader = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Ativo</span>
        <div className="row">
          <div className="col-xl-2 col-lg-3">
            <Field
              component={Input}
              inputId="codigoAtivo"
              name="codigoAtivo"
              type="text"
              label="Código do Ativo"
              readOnly={readOnly}
            />
          </div>

          <div className="col-xl-4 col-lg-9">
            <Field
              component={DropdownListInput}
              inputId="contratoAtivo"
              name="contratoAtivo"
              label="Contrato"
              options={contratoAtivoList}
              textField="descricao"
              valueField="id"
              readOnly={readOnly}
              filter="contains"
            />
          </div>

          <div className="col-xl-2 col-lg-3">
            <Field
              component={Input}
              inputId="dataAssinaturaContrato"
              name="dataAssinaturaContrato"
              type="date"
              label="Data Assinatura Contrato"
              readOnly={readOnly}
            />
          </div>

          <div className="col-xl-2 col-lg-3">
            <Field
              component={Input}
              inputId="dataVencimentoContrato"
              name="dataVencimentoContrato"
              type="date"
              label="Data de Vencimento"
              readOnly={readOnly}
            />
          </div>

          <div className="col-xl-2 col-lg-6">
            <Field
              component={DropdownListInput}
              inputId="performance"
              name="performance"
              label="Performance"
              options={performanceList}
              textField="value"
              valueField="key"
              filter="contains"
              readOnly={readOnly}
            />
          </div>

          <div className="col-xl-6">
            <Field
              component={DropdownListInput}
              inputId="segmentoAtivoDescricao"
              name="segmentoAtivo"
              label="Segmento"
              options={segmentoAtivoList}
              textField="descricao"
              valueField="id"
              readOnly={readOnly}
              filter="contains"
              onChange={(e) => {
                mudaDependenteOnChange("naturezaLastroList", [])
                mudaDependenteOnChange("idNaturezaLastro", null)
                if (e?.id) getNaturezaLastro(e)
              }}
            />
          </div>

          <div className="col-xl-6">
            <Field
              component={DropdownListInput}
              inputId="idNaturezaLastro"
              name="idNaturezaLastro"
              label="Natureza do Lastro"
              options={naturezaLastroList}
              textField="descricao"
              valueField="id"
              readOnly={readOnly || !segmentoAtivo}
              filter="contains"
            />
          </div>

          <div className="col-xl-5">
            <Field
              component={DropdownListInput}
              inputId="empresaServicerRazaoSocial"
              name="empresaServicer"
              label="Servicer"
              options={servicerList}
              textField="razaoSocial"
              valueField="idEmpresaPrestadora"
              filter="contains"
              readOnly={readOnly}
            />
          </div>

          <div className="col-xl-5">
            <Field
              component={DropdownListInput}
              inputId="empresaCustodianteRazaoSocial"
              name="empresaCustodiante"
              label="Custodiante"
              options={custodianteList}
              textField="razaoSocial"
              valueField="idEmpresaPrestadora"
              filter="contains"
              readOnly={readOnly}
            />
          </div>

          <div className="col-xl-2">
            <Field
              component={DropdownListInput}
              inputId="moeda"
              name="moeda"
              label="Moeda"
              options={moedasList}
              textField="descricao"
              valueField="descricao"
              filter="contains"
              readOnly={readOnly}
              onChange={(v) => changeMoeda(v?.simbolo)}
            />
          </div>

          <div className="col-xl-12 col-lg-12">
            <Field
              component={Textarea}
              inputId="descricaoAtivo"
              name="descricao"
              type="text"
              rows={1000}
              maxLength={1000}
              label="Descrição"
              readOnly={readOnly}
            />
          </div>
        </div>
      </div>
    </div>
  );

  let ativoDevedor = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Devedor</span>
        <div className="d-flex flex-column w-100">
          <div className="col more-fields p-0">
            <TransitionGroup>
              {devedorAtivoList &&
                devedorAtivoList.map((item, index) => {
                  const devedor = formValues?.devedorAtivoList?.[index]?.descricao;
                  
                  return (
                    <CSSTransition key={index} timeout={300} classNames="item">
                      <div key={index} className="more-fields-item">
                        <div className="d-flex">
                          <div className="row w-100">
                            <div className="col-12 col-lg-2">
                              <Field
                                component={DropdownListInput}
                                inputId="tipoDevedor"
                                name={`devedorAtivoList[${index}].tipoDevedor`}
                                label="Tipo"
                                options={tipoDevedorList}
                                textField="value"
                                valueField="key"
                                readOnly={readOnly}
                                filter="contains"
                              />
                            </div>
  
                            <div className={`form-group field-floating col-12 col-lg-${item.devedorAuditado == "Sim" ? '3' : '5'}`}>
                              <label className="control-label" style={{ top: -15 }}>Devedor</label>
                              <Field
                                isClearable
                                key={refreshKey}
                                component={GenericPickerInput}
                                inputId={`devedorAtivoList[${index}].descricao`}
                                fieldName={`devedorAtivoList[${index}].descricao`}
                                readOnly={readOnly}
                                options={fornecedorList}
                                formName={"ativoForm"}
                                loadData={getFornecedores}
                                placeholder={"Pesquisar fornecedor"}
                                isMulti={false}
                                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                                noOptionsMessage="Nenhum registro encontrado"
                                activeClassName={`inputPgmt ${readOnly ? "input-async disabled" : ""}`}
                                name={`devedorAtivoList[${index}].descricao`}
                                value={`devedorAtivoList[${index}].descricao`}
                                input={{
                                  value: devedor ? [{ label: devedor }] : null,
                                  onChange: (e) => {
                                    mudaDependenteOnChange(`devedorAtivoList[${index}].descricao`, e?.value || null)
                                    mudaDependenteOnChange(`devedorAtivoList[${index}].cnpj`, e ? extrairDocumentoFornecedor(e?.value) : null)
                                  }
                                }}
                              />
                            </div>
  
                            <div className="col-12 col-lg-2">
                              <Field
                                maxLength="18"
                                label="CNPJ/CPF"
                                readOnly={true}
                                inputId="devedorCNPJ"
                                component={MaskedFormatInput}
                                formatMask={customFormatCnpjCpf}
                                name={`devedorAtivoList[${index}].cnpj`}
                              />
                            </div>
  
                            <div className="col-12 col-lg-2">
                              <Field
                                component={DropdownListInput}
                                inputId="devedorAuditado"
                                name={`devedorAtivoList[${index}].devedorAuditado`}
                                label="Devedor auditado ?"
                                readOnly={readOnly}
                              />
                            </div>
  
                            <If
                              test={
                                item.devedorAuditado &&
                                item.devedorAuditado === "Sim"
                              }
                            >
                              <div className="col-12 col-lg-3">
                                <Field
                                  component={DropdownListInput}
                                  inputId="empresaAuditoria"
                                  name={`devedorAtivoList[${index}].empresaAuditoria`}
                                  label="Auditoria"
                                  options={auditoriaList}
                                  textField="razaoSocial"
                                  valueField="idEmpresaPrestadora"
                                  filter="contains"
                                  readOnly={readOnly}
                                />
                              </div>
                            </If>
  
                            <If test={!readOnly}>
                              <div className="col-12 col-lg-1 d-flex align-items-center minus-box-btn">
                                <Button
                                  classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize text-nowrap"
                                  activeClassName={"col-lg-auto p-0"}
                                  buttonStyle={"success"}
                                  action={() => removeDevedorAtivo(index)}
                                  icon={"trash"}
                                  name={"Remover"}
                                />
                              </div>
                            </If>
                          </div>
                        </div>
                      </div>
                    </CSSTransition>
                  )
                })}
            </TransitionGroup>
          </div>
          <If test={!readOnly}>
            <div className="mx-auto mt-3">
              <Button
                classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                activeClassName={"col-lg-auto p-0"}
                buttonStyle={"success"}
                action={() => addDevedorAtivo(devedorAtivoList.length)}
                icon={"plus"}
                name={"Adicionar"}
              />
            </div>
          </If>
        </div>
      </div>
    </div>
  );

  let ativoCedente = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Cedente</span>
        <div className="row">
          <div className="col-12 col-lg-3">
            <Field
              name="cnpj"
              label="CNPJ"
              maxLength="18"
              readOnly={true}
              inputId="cedenteCnpj"
              component={MaskedFormatInput}
              placeholder={CNPJ.placeholder}
              formatMask={customFormatCnpjCpf}
            />
          </div>

          <div className="form-group field-floating col-12 col-lg-6">
            <label className="control-label" style={{ top: -15 }}>Cedente</label>
            <Field
              isClearable
              key={refreshKey}
              component={GenericPickerInput}
              inputId="razaoSocial"
              fieldName="razaoSocial"
              readOnly={readOnly}
              options={fornecedorList}
              formName={"ativoForm"}
              loadData={getFornecedores}
              placeholder={"Pesquisar fornecedor"}
              isMulti={false}
              loadingMessage="Informe ao menos 3 caracteres para pesquisar"
              noOptionsMessage="Nenhum registro encontrado"
              activeClassName={`inputPgmt ${readOnly ? "input-async disabled" : ""}`}
              name="razaoSocial"
              value="razaoSocial"
              input={{
                value: formValues?.razaoSocial ? [{ label: formValues?.razaoSocial }] : null,
                onChange: (e) => {
                  mudaDependenteOnChange('razaoSocial', e?.value || null)
                  mudaDependenteOnChange('cnpj', e ? extrairDocumentoFornecedor(e?.value) : null)
                }
              }}
            />
          </div>

          <div className="col-12 col-lg-3">
            <Field
              component={MaskedInput}
              inputId="percentualCedido"
              name="percentualCedido"
              label="% Cedido"
              readOnly={readOnly}
              suffix={"%"}
              thousandSeparator={"."}
              decimalSeparator={","}
              decimalScale={4}
              fixedDecimalScale
              isAllowed={(value) => allowedPercentualCedido(value)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  let ativoOriginador = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Originador</span>
        <div className="d-flex flex-column w-100">
          <div className="col more-fields p-0">
            <TransitionGroup>
              {originadorAtivoList.map((item, index) => {
                const originador = formValues?.originadorAtivoList?.[index]?.originador?.razaoSocial;

                return (
                  <CSSTransition key={index} timeout={300} classNames="item">
                      <div key={index} className="more-fields-item">
                        <div className="d-flex">
                          <div className="row w-100">
                            <div className="col-12 col-lg-4">
                              <Field
                                label="CNPJ"
                                maxLength="18"
                                readOnly={true}
                                inputId="originadorCnpj"
                                component={MaskedFormatInput}
                                placeholder={CNPJ.placeholder}
                                formatMask={customFormatCnpjCpf}
                                name={`originadorAtivoList[${index}].originador.cnpj`}
                              />
                            </div>
    
                            <div className="form-group field-floating col-12 col-lg-7">
                              <label className="control-label" style={{ top: -15 }}>Originador</label>
                              <Field
                                isClearable
                                key={refreshKey}
                                component={GenericPickerInput}
                                inputId={`originadorAtivoList[${index}].originador.razaoSocial`}
                                fieldName={`originadorAtivoList[${index}].originador.razaoSocial`}
                                readOnly={readOnly}
                                options={fornecedorList}
                                formName={"ativoForm"}
                                loadData={getFornecedores}
                                placeholder={"Pesquisar fornecedor"}
                                isMulti={false}
                                loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                                noOptionsMessage="Nenhum registro encontrado"
                                activeClassName={`inputPgmt ${readOnly ? "input-async disabled" : ""}`}
                                name={`originadorAtivoList[${index}].originador.razaoSocial`}
                                value={`originadorAtivoList[${index}].originador.razaoSocial`}
                                input={{
                                  value: originador ? [{ label: originador }] : null,
                                  onChange: (e) => {
                                    mudaDependenteOnChange(`originadorAtivoList[${index}].originador.razaoSocial`, e?.value || null)
                                    mudaDependenteOnChange(`originadorAtivoList[${index}].originador.cnpj`, e ? extrairDocumentoFornecedor(e?.value) : null)
                                  }
                                }}
                              />
                            </div>
    
                            <If test={!readOnly}>
                              <div className="col-12 col-lg-1 d-flex align-items-center minus-box-btn">
                                <Button
                                  classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize text-nowrap"
                                  activeClassName={"col-lg-auto p-0"}
                                  buttonStyle={"success"}
                                  action={() => removeOriginador(index)}
                                  icon={"trash"}
                                  name={"Remover"}
                                />
                              </div>
                            </If>
                          </div>
                        </div>
                      </div>
                  </CSSTransition>
                )
              })}
            </TransitionGroup>
          </div>
          <If test={!readOnly}>
            <div className="mx-auto mt-3">
              <Button
                classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                activeClassName={"col-lg-auto p-0"}
                buttonStyle={"success"}
                action={() => addOriginador(originadorAtivoList.length)}
                icon={"plus"}
                name={"Adicionar"}
              />
            </div>
          </If>
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column mt-4">
      {ativoHeader}
      {ativoDevedor}
      {ativoCedente}
      {ativoOriginador}
    </div>
  );
}

const selector = formValueSelector("ativoForm");

const mapStateToProps = (state) => ({
  formValues: getFormValues('ativoForm')(state),
  pagamento: selector(state, "pagamento"),
  razaoSocial: selector(state, "razaoSocial") || "",
  originadorAtivoList: selector(state, "originadorAtivoList") || [],
  devedorAtivoList: selector(state, "devedorAtivoList") || [],
  segmentoAtivo: selector(state, "segmentoAtivo") || null,
  naturezaLastroList: selector(state, "naturezaLastroList") || [],
  contratoAtivoList: state.ativoReducer.ativoFormTypes.contratoAtivoList,
  segmentoAtivoList: state.ativoReducer.ativoFormTypes.segmentoAtivoList,
  auditoriaList: state.ativoReducer.ativoFormTypes.auditoriaList,
  servicerList: state.ativoReducer.ativoFormTypes.servicerList,
  custodianteList: state.ativoReducer.ativoFormTypes.custodianteList,
  performanceList: state.ativoReducer.ativoFormTypes.performanceList,
  tipoDevedorList: state.ativoReducer.ativoFormTypes.tipoDevedorList,
  moedasList: state.ativoReducer.ativoFormTypes.moedas,
  fornecedorList: state.prestadoraReducer.fornecedores,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    arrayInsert,
    arrayRemove,
    changeMoeda,
    mudaDependenteOnChange,
    getFornecedores,
    getNaturezaLastro,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AtivoFormGeral);
