/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { bindActionCreators } from "redux";
import Input from "../../../../../common/input/Input";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import {
  inserirNomePastaCedoc,
  limpaDependenteOnChange,
} from "../../../../../../actions/fundo/FundoAction";
import MaskedInput from "../../../../../common/input/MaskedInput";
import MaskedFormatInput from "../../../../../common/input/MaskedFormatInput";
import {
  CNPJ,
  customFormatCnpjCpf,
} from "../../../../../../utils/helpers/StringHelper";
import { Button as ButtonCedoc } from "@material-ui/core";
import FundoInserirNomePastaCedocModal from "./FundoInserirNomePastaCedocModal";
import Button from "../../../../../common/button/Button";
import { CSSTransition, TransitionGroup } from "react-transition-group";

class FundoFormGeral extends Component {
  addContaArrecadadora(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("fundoForm", "fundoContaArrecadadoras", index, {});
    }
  }

  removeContaArrecadadora(index) {
    if (!this.readOnly && this.props.fundoContaArrecadadoras.length > 0) {
      this.props.arrayRemove("fundoForm", "fundoContaArrecadadoras", index);
    }
  }

  addClasseFundo(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("fundoForm", "classes", index, {});
    }
  }

  removeClasseFundo(index) {
    if (!this.readOnly && this.props.classes.length > 0) {
      this.props.arrayRemove("fundoForm", "classes", index);
    }
  }

  render() {
    const {
      limpaDependenteOnChange,

      readOnly,
      usuarios,
      classesCvms,
      tiposAnBima,
      condominios,
      tiposContaFiduciarias,
      administradorFiduciarios,
      gestoras,
      gestorasII,
      consultorCreditos,
      controladorias,
      distribuidores,
      custodiantes,
      agenteMonitoramentos,
      agenteFormalizacoes,
      agenteCobrancaJudiciais,
      agenteCobrancaExtrajudiciais,
      auditorias,
      assessorLegais,
      escrituradores,
      status,
      bancoList,
      fundoContaArrecadadoras,
      classes,
      possuiMultiGestao,
      possuiVencimento,

      inserirNomePastaCedoc,

      moedaDinamica,
      fundoOperacao,
      fundoObj,
      dataDaPrimeiraIntegralizacao
    } = this.props;

    let moedaSimbolo = moedaDinamica || fundoOperacao?.moeda?.simbolo || "R$";

    let fundoHeader = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Fundo</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={Input}
                inputId="nomeDoFundo"
                name="nomeDoFundo"
                type="text"
                label="Nome do Fundo"
                readOnly={readOnly}
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                label="CNPJ do Fundo"
                inputId="cnpjDoFundo"
                name="cnpjDoFundo"
                maxLength="18"
                readOnly={readOnly}
                className={"filter-input"}
                component={MaskedFormatInput}
                placeholder={CNPJ.placeholder}
                formatMask={customFormatCnpjCpf}
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={Input}
                maxLength={4}
                inputId="siglaDoFundo"
                name="siglaDoFundo"
                type="text"
                label="Sigla do Fundo"
                readOnly={readOnly}
                icon="info-circle"
                iconColor="#630a3679"
                tooltipMessage="Sigla do Fundo deve conter até 4 caracteres, sendo utilizada para gerar o Código Opea do Fundo"
                tooltipId="sgfd"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="classeCvm"
                name="classeCvm"
                label="Classe CVM"
                options={classesCvms}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="tipoAnbima"
                name="tipoAnbima"
                label="Tipo ANBIMA"
                options={tiposAnBima}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="condominio"
                name="condominio"
                label="Condomínio"
                options={condominios}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let fundoControle = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Controle</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idUsuarioGestao"
                name="idUsuarioGestao"
                label="Gestão"
                options={usuarios}
                textField="nome"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idUsuarioServicoCredito"
                name="idUsuarioServicoCredito"
                label="Serviço de Crédito"
                options={usuarios}
                textField="nome"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="idUsuarioCompliance"
                name="idUsuarioCompliance"
                label="Compliance"
                options={usuarios}
                textField="nome"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="status"
                name="status"
                label="Status"
                options={status}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            {!readOnly ? (
              <div className="col-12 col-lg-auto pl-0 pl-lg-0 pl-2 mb-3">
                <ButtonCedoc
                  className="w-100"
                  variant="contained"
                  onClick={() => inserirNomePastaCedoc()}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#630A37",
                    height: "38px",
                    top: "23px",
                    left: "17px",
                  }}
                >
                  Vincular pasta no CEDOC
                </ButtonCedoc>
              </div>
            ) : (
              <div className="col-lg-3 col-lg-3">
                <Field
                  component={Input}
                  inputId="idCedoc"
                  name="idCedoc"
                  label="ID da Pasta do Patrimônio Separado no CEDOC"
                  type="text"
                  maxLength={90}
                  filter="contains"
                  readOnly={readOnly}
                  outerClassName={readOnly ? "disabled-field" : ""}
                  required
                />
              </div>
            )}

            <FundoInserirNomePastaCedocModal />
          </div>
        </div>
      </div>
    );

    let fundoDatasValores = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Datas e Valores</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={Input}
                inputId="dataDeConstituicao"
                name="dataDeConstituicao"
                type="date"
                label="Data de Constituição"
                readOnly={readOnly}
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={Input}
                inputId="dataRegistro"
                name="dataRegistro"
                type="date"
                label="Data de Registro"
                readOnly={readOnly}
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="possuiVencimento"
                name="possuiVencimento"
                label="Possui Vencimento?"
                readOnly={readOnly}
                filter="contains"
                onChange={() => limpaDependenteOnChange("dataDeVencimento")}
                required
              />
            </div>

            {possuiVencimento == "Sim" && (
              <div className="col-lg-3 col-12">
                <Field
                  component={Input}
                  inputId="dataDeVencimento"
                  name="dataDeVencimento"
                  type="date"
                  label="Data de Vencimento"
                  readOnly={readOnly}
                  required
                />
              </div>
            )}

            {fundoObj && (
              <>
                <div className="col-lg-3 col-12">
                  <Field
                    component={Input}
                    inputId="dataDaPrimeiraIntegralizacao"
                    name="dataDaPrimeiraIntegralizacao"
                    type={!dataDaPrimeiraIntegralizacao ? 'text' : 'date'}
                    label="Data da Primeira Integralização"
                    readOnly={true}
                  />
                </div>

                <div className="col-lg-3 col-12">
                  <Field
                    component={MaskedInput}
                    inputId="totalDistribuido"
                    name="totalDistribuido"
                    label="Total Distribuído"
                    readOnly={true}
                    prefix={`${moedaSimbolo} `}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={8}
                    fixedDecimalScale
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    let fundoParticipantes = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Participantes</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="administradorFiduciario"
                name="administradorFiduciario"
                label="Administrador Fiduciário"
                options={administradorFiduciarios}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="gestora"
                name="gestora"
                label="Gestora"
                options={gestoras}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="possuiMultiGestao"
                name="possuiMultiGestao"
                label="Multigestão?"
                readOnly={readOnly}
                filter="contains"
                onChange={() => limpaDependenteOnChange("gestoraII", null)}
                required
              />
            </div>

            {possuiMultiGestao == "Sim" && (
              <div className="col-lg-3 col-12">
                <Field
                  component={DropdownListInput}
                  inputId="gestoraII"
                  name="gestoraII"
                  label="Gestora II"
                  options={gestorasII}
                  textField="razaoSocialComCnpj"
                  valueField="idEmpresaPrestadora"
                  readOnly={readOnly}
                  filter="contains"
                  required
                />
              </div>
            )}

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="consultorCredito"
                name="consultorCredito"
                label="Consultor de Crédito"
                options={consultorCreditos}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="controladoria"
                name="controladoria"
                label="Controladoria"
                options={controladorias}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="distribuidor"
                name="distribuidor"
                label="Distribuidor"
                options={distribuidores}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="escriturador"
                name="escriturador"
                label="Escriturador"
                options={escrituradores}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="custodiante"
                name="custodiante"
                label="Custodiante"
                options={custodiantes}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteMonitoramento"
                name="agenteMonitoramento"
                label="Agente de Monitoramento"
                options={agenteMonitoramentos}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteFormalizacao"
                name="agenteFormalizacao"
                label="Agente de Formalização"
                options={agenteFormalizacoes}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="assessorLegal"
                name="assessorLegal"
                label="Assessor Legal"
                options={assessorLegais}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
              />
            </div>

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteCobrancaJudicial"
                name="agenteCobrancaJudicial"
                label="Agente de Cobrança Judicial"
                options={agenteCobrancaJudiciais}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteCobrancaExtrajudicial"
                name="agenteCobrancaExtrajudicial"
                label="Agente de Cobrança Extrajudicial"
                options={agenteCobrancaExtrajudiciais}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="auditoria"
                name="auditoria"
                label="Auditor Independente"
                options={auditorias}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let fundoContasFiduciarias = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Contas Fiduciárias</span>
          <div className="d-flex flex-column w-100">
            <div className="col more-fields p-0">
              <TransitionGroup>
                {fundoContaArrecadadoras.map((item, index) => (
                  <CSSTransition key={index} timeout={300} classNames="item">
                    <div key={index} className="more-fields-item">
                      <div className="d-flex">
                        <div className="row w-100">
                          <div className="col-12 col-lg-3">
                            <Field
                              component={DropdownListInput}
                              inputId="tipo"
                              name={`fundoContaArrecadadoras[${index}].tipo`}
                              label="Tipo"
                              options={tiposContaFiduciarias}
                              textField="value"
                              valueField="key"
                              filter="contains"
                              readOnly={readOnly}
                              outerClassName={readOnly ? "disabled-field" : ""}
                              required
                            />
                          </div>

                          <div className="col-12 col-lg-4">
                            <Field
                              component={DropdownListInput}
                              inputId="idBanco"
                              name={`fundoContaArrecadadoras[${index}].idBanco`}
                              label="Banco"
                              options={bancoList}
                              textField="nome"
                              valueField="id"
                              filter="contains"
                              readOnly={readOnly}
                              outerClassName={readOnly ? "disabled-field" : ""}
                              required
                            />
                          </div>

                          <div className="col-12 col-lg-1">
                            <Field
                              component={DropdownListInput}
                              inputId="idBanco"
                              name={`fundoContaArrecadadoras[${index}].idBanco`}
                              label="Cód. Banco"
                              options={bancoList}
                              textField="codigo"
                              valueField="id"
                              filter="contains"
                              readOnly={true}
                              outerClassName={"disabled-field"}
                              required
                            />
                          </div>

                          <div className="col-12 col-lg-1">
                            <Field
                              component={Input}
                              inputId="agencia"
                              name={`fundoContaArrecadadoras[${index}].agencia`}
                              type="text"
                              label="Agência"
                              readOnly={readOnly}
                              outerClassName={readOnly ? "disabled-field" : ""}
                              required
                            />
                          </div>

                          <div className="col-12 col-lg-1">
                            <Field
                              component={Input}
                              inputId="conta"
                              name={`fundoContaArrecadadoras[${index}].conta`}
                              type="text"
                              label="Conta"
                              readOnly={readOnly}
                              outerClassName={readOnly ? "disabled-field" : ""}
                              required
                            />
                          </div>

                          <div className="col-12 col-lg-1">
                            <Field
                              component={Input}
                              inputId="digito"
                              name={`fundoContaArrecadadoras[${index}].digito`}
                              type="text"
                              label="Dígito"
                              maxLength="3"
                              readOnly={readOnly}
                              outerClassName={readOnly ? "disabled-field" : ""}
                              required
                            />
                          </div>

                          {!readOnly && (
                            <div className="col-12 col-lg-1 d-flex align-items-center minus-box-btn">
                              <Button
                                classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize text-nowrap"
                                activeClassName={"col-lg-auto p-0"}
                                buttonStyle={"success"}
                                action={() => this.removeContaArrecadadora(index)}
                                icon={"trash"}
                                name={"Remover"}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                  ))}
              </TransitionGroup>
            </div>

            {!readOnly && (
              <div className="mx-auto mt-3">
                <Button
                  classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                  activeClassName={"col-lg-auto p-0"}
                  buttonStyle={"success"}
                  action={() =>
                    this.addContaArrecadadora(fundoContaArrecadadoras.length)
                  }
                  icon={"plus"}
                  name={"Adicionar"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );

    let fundoClasses = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Classes</span>
          <div className="d-flex flex-column w-100">
            <div className="col more-fields p-0">
              <TransitionGroup>
                {classes.map((item, index) => (
                  <CSSTransition key={index} timeout={300} classNames="item">
                    <div key={index} className="more-fields-item">
                        <div className="d-flex">
                          <div className="row w-100">
                            <div className="col-lg-5 col-12">
                              <Field
                                component={Input}
                                inputId="nomeDaClasse"
                                name={`classes[${index}].nomeDaClasse`}
                                type="text"
                                label="Nome da Classe"
                                readOnly={readOnly}
                                outerClassName={readOnly ? "disabled-field" : ""}
                                required
                              />
                            </div>

                            <div className="col-lg-6 col-12">
                              <Field
                                component={Input}
                                inputId="observacao"
                                name={`classes[${index}].observacao`}
                                type="text"
                                label="Observação"
                                readOnly={readOnly}
                                outerClassName={readOnly ? "disabled-field" : ""}
                              />
                            </div>

                            {!readOnly && (
                              <div className="col-12 col-lg-1 d-flex align-items-center minus-box-btn">
                                <Button
                                  classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize text-nowrap"
                                  activeClassName={"col-lg-auto p-0"}
                                  buttonStyle={"success"}
                                  action={() => this.removeClasseFundo(index)}
                                  icon={"trash"}
                                  name={"Remover"}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>

            {!readOnly && (
              <div className="mx-auto mt-3">
                <Button
                  classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                  activeClassName={"col-lg-auto p-0"}
                  buttonStyle={"success"}
                  action={() => this.addClasseFundo(classes.length)}
                  icon={"plus"}
                  name={"Adicionar"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <div className="d-flex flex-column mt-4">
        {fundoHeader}
        {fundoControle}
        {fundoDatasValores}
        {fundoParticipantes}
        {fundoContasFiduciarias}
        {/* {fundoRegraSubordinacao} */}
        {fundoClasses}
      </div>
    );
  }
}

const selector = formValueSelector("fundoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      arrayInsert,
      arrayRemove,
      inserirNomePastaCedoc,
      limpaDependenteOnChange,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  usuarios: state.fundoReducer.fundoFormTypes.usuarios,
  classesCvms: state.fundoReducer.fundoFormTypes.classesCvms,
  tiposAnBima: state.fundoReducer.fundoFormTypes.tiposAnBima,
  condominios: state.fundoReducer.fundoFormTypes.condominios,
  tiposContaFiduciarias:
    state.fundoReducer.fundoFormTypes.tiposContaFiduciarias,
  administradorFiduciarios:
    state.fundoReducer.fundoFormTypes.administradorFiduciarios,
  gestoras: state.fundoReducer.fundoFormTypes.gestoras,
  gestorasII: state.fundoReducer.fundoFormTypes.gestorasII,
  consultorCreditos: state.fundoReducer.fundoFormTypes.consultorCreditos,
  controladorias: state.fundoReducer.fundoFormTypes.controladorias,
  distribuidores: state.fundoReducer.fundoFormTypes.distribuidores,
  custodiantes: state.fundoReducer.fundoFormTypes.custodiantes,
  agenteMonitoramentos: state.fundoReducer.fundoFormTypes.agenteMonitoramentos,
  agenteFormalizacoes: state.fundoReducer.fundoFormTypes.agenteFormalizacoes,
  agenteCobrancaJudiciais:
    state.fundoReducer.fundoFormTypes.agenteCobrancaJudiciais,
  agenteCobrancaExtrajudiciais:
    state.fundoReducer.fundoFormTypes.agenteCobrancaExtrajudiciais,
  auditorias: state.fundoReducer.fundoFormTypes.auditorias,
  assessorLegais: state.fundoReducer.fundoFormTypes.assessorLegais,
  escrituradores: state.fundoReducer.fundoFormTypes.escrituradores,
  status: state.fundoReducer.fundoFormTypes.status,
  bancoList: state.fundoReducer.fundoFormTypes.bancoList,
  fundoObj: state.fundoReducer.fundoFormTypes.fundo,
  fundoContaArrecadadoras: selector(state, "fundoContaArrecadadoras") || [],
  classes: selector(state, "classes") || [],
  possuiMultiGestao: selector(state, "possuiMultiGestao") || null,
  possuiVencimento: selector(state, "possuiVencimento") || null,
  dataDaPrimeiraIntegralizacao: selector(state, "dataDaPrimeiraIntegralizacao") || null,
});

export default connect(mapStateToProps, mapDispatchToProps)(FundoFormGeral);
